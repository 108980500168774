<template>
  <div class="p-root">
    <img :src="banner" class="full-width" />

    <div class="full-width column-center" style="padding: 50px 0">
      <span class="intro-title">Avant-propos</span>
      <!-- <span class="intro-title">前 言</span> -->
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-content-width intro-red" style="font-size: 22px; margin-top: 30px;text-align:center">Une
        rencontre de cœur et de
        génie qui dépasse le
        temps, l'espace, les frontières, l’âge et l'identité</span>
      <!-- <span class="intro-red" style="font-size: 22px">A spiritual conversation across time, space, age and
        identity</span> -->

      <span class="intro-content-width intro-18" style="margin-top: 20px">À l’occasion du 150ème anniversaire de la
        naissance de Marcel Proust, le « Château de Swann » s'ouvre au public. Lieu magique et caché jusqu'à présent, il
        s’agit de la demeure des personnages de fiction sortis tout droit de l’œuvre « Du côté de chez Swann ». Ce
        château à l'architecture merveilleuse et raffinée qui se trouve dans un parc où des arbres centenaires et
        orientaux cachent jusqu’à ce jour la petite Chapelle, symbole de l’amour du jeune Marcel, se fait ressentir
        aujourd’hui encore cette source mystérieuse qui avait nourri, il y a plus d’un siècle, la graine d’écrivain chez
        Marcel Proust.

        Trois jours avant Les Journées Européennes du Patrimoine qui suscite d’énormes intérêts des passionnés de la
        littérature, le Président Macron arrive dans le petit bourg natal de l’illustre écrivain, en offrant un chèque
        de 400 mille euros et une dédicace : « Ici, la littérature a pris possession des lieux pour habiter nos vies ».

        Une Co-création entre 5 grands artistes reconnus et des enfants du monde entier

        Lors des Journées Européennes du Patrimoine, cinq artistes de renom ont été invités à venir peindre le château
        de Swann et vont créer chacun une œuvre... « inachevée ».

        Un appel sera donc lancé aux enfants du monde entier pour compléter ces œuvres inachevées des maîtres qui ont
        laissé place à leur imagination, leur sensibilité, leur pensée et leur ingéniosité.

        Voici le nœud de ce jeu artistique qui permettra aux enfants de déployer leur imagination et leur génie
        artistique. En effet, des « espaces blancs » se cachent dans chaque création des artistes-peintres, les enfants
        doivent les trouver et puis les compléter, avant de donner eux-mêmes une touche personnelle selon l’imagination
        et l’inspiration de chacun. Au fait, tout au long de cette opération, les enfants peuvent regarder les vidéos
        des artistes, dans lesquelles les maîtres expliquent leurs techniques, leurs inspirations et leurs ingéniosités
        en donnant des indications pour guider leurs jeunes partenaires.

        Picasso a dit « chaque enfant est un peintre né ». Quand ces maillons d’enfants du monde entier rencontrent de
        grands artistes reconnus à travers leur pinceau et leurs couleurs, que se passera-t-il ? Quelles seront les
        étincelles magiques et inattendues ? Et quelles œuvres exceptionnelles naîtront ?
      </span>
    </div>

    <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 0">
      <span class="intro-title">Participants</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px">Les candidats seront classés
        selon cinq tranches d’âge</span>
      <span class="intro-black intro-18" style="margin-top: 10px">3 à 5 ans / 6 à 7ans / 8 à 9 ans / 10 à 12 ans et 13 à
        14 ans</span>
    </div>

    <!-- <div class="full-width column-center" style="background-color: #e0cfb8; padding: 50px 320px">
      <span class="intro-title">Thème</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />
      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px">Remembrance of Things Past -
        Mystery of Swan</span>
    </div> -->

    <div class="full-width column-center" style="background-color: #e0cfb8; padding: 50px 320px">
      <span class="intro-title">Prix</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <div class="row intro-content-width" style="justify-content:center;font-size: 24px; margin-top: 30px">
        Au final, un prix artistique général sera décerné à tous les enfants qui auront correctement achevé leurs
        tableaux.

        En outre, de nombreuses personnalités s'étant distinguées dans leur domaine de compétence (ingénieurs, médecins,
        écrivains, essayistes, journalistes, scientifiques, éditeurs, avocats, etc.) seront invitées à faire leur
        sélection parmi les innombrables œuvres selon leur préférence et aux thèmes multiples : la rêverie,
        l’enthousiasme, la turbulence, la vivacité, la mélancolie, et l’extravagance, etc...
      </div>
    </div>

    <div class="full-width column-center" style="background-color: #072736; padding: 50px; color: white">
      <span class="intro-title">Timing</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <!-- <span class="intro-18" style="margin-top: 30px"> Registration and submission of entries
      </span>
      <span class="intro-red intro-18" style="font-weight: bold">October 15 - December 30,2021</span>

      <span class="intro-18" style="margin-top: 30px"> Announcement of the results of the preliminary selection (Award
        of Merit)</span>
      <span class="intro-18">January 5, 2022</span>
      <span class="intro-18"> Form of announcement: Organizing committee and registration agencies</span>

      <span class="intro-18" style="margin-top: 30px"> Announcement of the results of the review (Gold Award / Un
        Certain Regard)</span>
      <span class="intro-18">January 18, 2022</span>
      <span class="intro-18"> Form of announcement: Organizing committee and registration agencies</span>

      <span class="intro-18" style="margin-top: 30px"> Announcement date of the French tour and special award</span>
      <span class="intro-18">February 2022</span> -->
      <span class="intro-content-width intro-18">La candidature sera ouverte aux enfants de la Chine le Juillet 2022.
        Des 15 autres pays
        participants (la France, l’Italie, la Grèce, la Russie, la Biélorussie, le Liban, le Mexique, la Brésil, la
        Roumanie, le Japon, la Corée, le Kazakhstan, le Sénégal, etc.) vont poursuivre la démarche selon l’avancement
        dans chaque pays. </span>
    </div>

    <!-- <div class="full-width column-center" style="padding: 50px 320px">
      <span class="intro-title">L ’unité Unit Setup</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red intro-18" style="font-weight: bold; font-size: 18px; margin-top: 30px"> Main
        Competition</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">·
        Each of the five international artists creates one “unfinished work.” Participants will be able to see clear
        pictures of their works on the official website or in the “Co-creative Artists” section of WeChat Subcription.
        Upon completion of the application form and payment of the fee, the organizers will provide a high resolution
        electronic copy of the international artist’s “unfinished works” by email or by directly contacting the
        applicant. Applicants can choose one of these works to begin their creation. Applicants can select the
        “unfinished” work according to their ages. It is permissible to create the painting in its original, enlarged or
        reduced version. Children can choose to finish in the middle or around the original painting. There is only one
        requirement that the work must be created independently by the entrant aged 3-14. The five artists will continue
        to complete the “unfinished” work, which will be exhibited alongside exhibitors’ work at the Louvre in
        France.</span>
    </div> -->

    <!-- <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 320px">
      <span class="intro-title">Awards</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-content-width intro-red"
        style="font-size: 22px;font-weight: bold;text-align: center;margin-top: 30px;">[Main Competition Unit]
        Preliminary Selection (to be announced on January 5) for the "Little Maupassant Award of Excellence"</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Number of finalists: more than 90% of the works</span>
        <span class="intro-content-width">·Selection criteria: The organizing committeewill select the works that do not
          meet the requirements of the
          exhibition and submit them to the organizing committee for a second selection stage. (The work of directly
          registration on the official website and WeChat Supscrition is selected by the organizing committee itself.
          )</span>
        <span class="intro-content-width">· The award Prizes:</span>
        <span class="intro-content-width">
          1）a “Little Maupassant Award of Excellence” certificate signed electronically by the international
          artist;</span>
        <span class="intro-content-width">
          2）a chance to win the “Un Certainty Award” and the “Little Maupassant Award of Excellence” if they are
          selected by the organizing committee. </span>
        <span class="intro-content-width">
          3）12 boxes of “Petit Maupassant Children’s Milk” (200 ml), specially supplied by the French national producer
          “Normandie 1905”. (200 ml) (to be distributed upon successful registration)</span>
        <span class="intro-content-width"> 4）Exhibition in the “Online Showroom” on the website.</span>
      </div>
      <span class="intro-content-width intro-red"
        style="font-size: 22px;font-weight: bold;text-align: center;margin-top: 30px;">[Main Competition Unit] All works
        exhibited in the “Online Gallery” will have the opportunity to win the “Socially Noteworthy Award”.</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Number of finalists: unlimited</span>
        <span class="intro-content-width">·
          Selection Criteria: Works will be uploaded to the “Online Exhibition Hall” whenever they are received by the
          Organizing Committee, and by 18 January 2022, accomplished “international and domestic community members” will
          be invited to select their favorite works from among the reviewed works and write a “Recommendation”. “The
          selection process will be completed by 18 January 2022, with the selection of the works to be reviewed and a
          recommendation written.</span>
        <span class="intro-content-width">· The award Prizes:</span>
        <span class="intro-content-width"> 1）a touring exhibition in France</span>
        <span class="intro-content-width">
          2）a certificate of the Un Certain Regard Award with the recommendation of the “accomplished member of
          society”.</span>
      </div>
      <span class="intro-content-width intro-red"
        style="font-size: 22px;font-weight: bold;text-align: center;margin-top: 30px;">[Main Competition] Review (to be
        announced on 18 January) to receive the "Little Maupassant Gold Award"</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Number of finalists: 1,000+</span>
        <span class="intro-content-width">· Selection criteria: the organizing committee will upload the works to the
          “official website” and invite a “jury of Chinese and French artists” to select the gold medal among the
          reviewed works.</span>
        <span class="intro-content-width">· Prizes:</span>
        <span class="intro-content-width"> 1）a touring exhibition in France</span>
        <span class="intro-content-width"> 2）the “Petit Maupassant Gold Medal”</span>
        <span class="intro-content-width">Note: the “Un Certain Regard” and “Petit Maupassant Gold Medal” works that are
          eligible for the touring exhibition can If you choose to participate in the exhibition, you will be required
          to pay an international exhibition fee of RMB 1,000 per work.</span>
        <span class="intro-content-width">
          1）Certificate of Louvre Exhibition issued by France-China Exchange Promotion Association;</span>
        <span class="intro-content-width">
          2）Electronic version of works on tour exhibition in France provided by the organizing Committee;</span>
        <span class="intro-content-width">
          3）Works included in the album of International Art Creation Tour Exhibition;</span>
        <span class="intro-content-width">
          4）Works and author information have a chance to appear in Chinese and foreign media reports</span>
        <span class="intro-content-width">
          5）A chance to win the “Special Prize” (50) chosen at the exhibition in the Louvre</span>
      </div>
      <span class="intro-black" style="font-size: 22px; font-weight: bold; margin-top: 30px">Touring Exhibition in
        France</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· Time: February 2022</span>
        <span class="intro-content-width">· Location: Louvre, Paris, Chateau Marcel Proustswan, France</span>
        <span class="intro-content-width">· Note: Works that have won the Un Certain Regard Award and the Petit
          Maupassant Gold Award are entitled to participate in the French touring exhibition. If you choose to
          participate in the exhibition, you will need to pay an additional “international exhibition fee of RMB 1,000
          per work. In the French touring exhibition, the five artists will also announce their “complete paintings” and
          exhibit them together.</span>
      </div>
    </div> -->

    <!-- <div class="full-width column-center" style="background-color: #e0cfb8; padding: 50px 320px">
      <span class="intro-title">Description of fees</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px"> Materials fee (pay at the
        time of registration) </span>
      <span class="intro-red" style="font-size: 20px; margin-top: 10px">RMB 300 per entry</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">The registration form must be completed and
        this materials fee must be paid at the time of registration, which includes: handling fee, translation fee,
        international contact fee, judging fee, shipping fee, etc.</span>

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px"> International exhibition fee
        (payable after passing the review) </span>
      <span class="intro-red" style="font-size: 20px; margin-top: 10px">RMB 1,000 per work</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">After the announcement of the results of the
        review on January 18, 2022, the winners of all the Units will be able to choose whether or not to participate in
        the traveling exhibition at the Louvre and Château de Swan. The works that have been awarded the Un Certain
        Regard Prize and the Petit Maupassant Gold Prize will be eligible for participation in the traveling exhibition
        at the Louvre and Château de Swan. This fee is payable for works selected for exhibition.</span>
    </div> -->

    <!-- <div class="full-width column-center" style="padding: 50px 320px">
      <span class="intro-title">Notes for participation</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 30px">
        <div class="intro-black" style="font-weight: bold">·Detailed process:</div>
        <div style="flex: 1">
          Applicants will receive a high resolution image of the unfinished work of the international painter after
          registering through the “official website” or “WeChat Subscription” or “offline partner organisations”. After
          the completion of the work, the original work will be delivered to the group. Once you have completed your
          work, send the original work by courier to the organizing committee (see “Contact Us” on this website).
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·Type of work:</div>
        <div style="flex: 1">
          Ink and wash, watercolor painting, gouache, oil, crayon, drawing, cartoon, caricature, colored pencil,
          printmaking, collage, pen and pencil, composite painting and other types of work are all eligible for
          exhibition. (Note: digital paintings and other electronic works cannot be exhibited)
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·Specifications:</div>
        <div style="flex: 1">
          Thick paper, size between A4 (297mm × 210mm) and A2 (594mm × 420mm). Works that do not meet the size are
          considered invalid.
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·Requirements:</div>
        <div style="flex: 1">
          Participants in the main competition can choose one of the “unfinished works by international painters” to
          continue their work. Only works created by the artist are allowed to be exhibited, not collaborative or
          cooperative works. Fragile, uneven and uneven shapes are not allowed. No insect-prone grains or potentially
          perishable or fragile materials are allowed.
        </div>
      </div>
    </div> -->

    <div class="full-width" style="background-color: #2588ce; height: 20px"></div>

    <div class="full-width column-center" style="background-color: #072736; padding: 50px; color: white">
      <span class="intro-title">Déclaration du droit d‘auteur</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <!-- <div class="row intro-content-width intro-18">Le droit d’auteur des œuvres et les déclarations connexes lors de la
        première édition de la CoCréa – une co-création artistique internationale
        1. Une fois que le participant s'est inscrit avec succès et a payé le frais de dossiers, ce dernier ne sera pas
        remboursé si le participant ne peut pas participer à l'événement pour des raisons personnelles.
        2. Le participant doit obtenir le consentement de son tuteur. Le participant ou son tuteur doit connaitre,
        confirmer et s'engager à respecter strictement les règles de cet événement (y compris le droit d’auteur des
        œuvres qui seront exposées et les déclarations connexes).
        3. L’œuvre soumise par le participant doit être créée indépendamment lui-même.
        4. Tous les documents visuels ou écrits soumis par le participant doivent être véridiques et ne doivent pas
        constituer une violation du droit d’auteur, du droit à l’image, du droit au respect de la vie privée, du droit à
        l’honneur et à la réputation ou d'autres droits légaux de tiers. Sinon, sa participation sera annulée et les
        conséquences juridiques pertinentes sont à la charge du participant lui-même.
        5. Sans le consentement de l'organisateur de l'événement, aucun tiers ne peut utiliser aucune information,
        image, etc du participant et des œuvres sous quelque forme que ce soit. Cette restriction ne prendra pas fin
        avec l’achèvement de l'événement.
        6. Pendant l'exposition, le participant n’est pas autorisé à céder l’œuvre exposée ou à permettre à des tiers de
        l’utiliser. Sans l'accord écrit de l'organisateur de l'événement, les œuvres exposées ne peuvent être utilisées
        à des fins commerciales.
        7. Si un acte de violation du droit d'auteur ou d'autres droits de propriété intellectuelle se produit en raison
        de la négligence ou de la volonté du participant, provoquant toute perte ou dommage à l'organisateur de
        l'événement, le participant en sera responsable et assumera toutes les responsabilités.
        8. Toutes les œuvres exposées appartiennent à l'Association pour le Développement des Échanges France-Chine
        (ci-après dénommée ADEFC) ou à ses ayants droit. Aucune œuvre exposée sera restituée. Si une œuvre exposée est
        par la suite mise aux enchères ou vendue, la répartition du produit net de la vente sera 50 % pour l'ADEFC, 15 %
        pour l’artistes de l’œuvre initiale, 35% pour le participant (hors taxes et commissions).
        9. Dans le cas de l'une des infractions suivantes au cours de l’événement, l’infracteur assumera, en fonction de
        la situation, des responsabilités civiles telles que l'arrêt de l'infraction, l'élimination de l'impact, la
        présentation d'excuses, l'indemnisation des pertes, etc. Si l'intérêt public est lésé, l’infracteur peut être
        ordonné par le département de l'administration du droit d'auteur à arrêter l'infraction, à confisquer les gains
        illégaux, à confisquer et à détruire les copies contrefaites, et peut être imposé d’une amende. Si les
        circonstances sont suffisamment graves pour constituer un crime, on fera l'objet d'une enquête de responsabilité
        légale conformément à la loi :
        a. Exposer ses propres œuvres sans l'autorisation du titulaire du droit d'auteur ;
        b. Exposer les œuvres d'autrui comme les leurs sans l'autorisation des auteurs ;
        c. Déformer, falsifier ou plagier les œuvres d'autrui ;
        d. Autres actes de violation du droit d'auteur et d’autres droits.
        10. En cas de facteurs non humains ou d'autres événements imprévus indépendants de la volonté de l'organisateur
        de l'événement, y compris les tremblements de terre, les incendies, les inondations, les émeutes, les guerres,
        les grèves, les épidémies, les changements dans les lois gouvernementales et autres événements de force majeure,
        l'organisateur se réserve le droit d'annuler, de reporter ou d'adapter en conséquence l’événement et son
        organisation.
        11. Le participant et ses tuteurs ont lu attentivement le contenu de cette déclaration. Une fois qu'ils ont
        participé à tout ou une telle partie de l’événement, il est considéré que le participant et ses tuteurs ont
        totalement compris et accepté tous les éléments, y compris cette déclaration et sont prêts à supporter toutes
        les conséquences juridiques découlant de l'acceptation de cette déclaration.
        12. L'ADEFC ou ses ayants droit se réserve le droit d'interprétation finale des règles de l’événement.
      </div> -->

      <div class="row intro-content-width" style="justify-content:center;font-size: 24px; margin-top: 30px">
        Le droit d’auteur des œuvres et les déclarations connexes lors de la première édition de la CoCréa – une
        co-création artistique internationale</div>

      <div class="row intro-18 intro-content-width" style="margin-top: 40px;">
        1. Une fois que le participant s'est inscrit avec succès et a payé le frais de dossiers, ce dernier ne sera pas
        remboursé si le participant ne peut pas participer à l'événement pour des raisons personnelles.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        2. Le participant doit obtenir le consentement de son tuteur. Le participant ou son tuteur doit connaitre,
        confirmer et s'engager à respecter strictement les règles de cet événement (y compris le droit d’auteur des
        œuvres qui seront exposées et les déclarations connexes).
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        3. L’œuvre soumise par le participant doit être créée indépendamment lui-même.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        4. Tous les documents visuels ou écrits soumis par le participant doivent être véridiques et ne doivent pas
        constituer une violation du droit d’auteur, du droit à l’image, du droit au respect de la vie privée, du droit à
        l’honneur et à la réputation ou d'autres droits légaux de tiers. Sinon, sa participation sera annulée et les
        conséquences juridiques pertinentes sont à la charge du participant lui-même.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        5. Sans le consentement de l'organisateur de l'événement, aucun tiers ne peut utiliser aucune information,
        image, etc du participant et des œuvres sous quelque forme que ce soit. Cette restriction ne prendra pas fin
        avec l’achèvement de l'événement.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        6. Pendant l'exposition, le participant n’est pas autorisé à céder l’œuvre exposée ou à permettre à des tiers de
        l’utiliser. Sans l'accord écrit de l'organisateur de l'événement, les œuvres exposées ne peuvent être utilisées
        à des fins commerciales.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        7. Si un acte de violation du droit d'auteur ou d'autres droits de propriété intellectuelle se produit en raison
        de la négligence ou de la volonté du participant, provoquant toute perte ou dommage à l'organisateur de
        l'événement, le participant en sera responsable et assumera toutes les responsabilités.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        8. Toutes les œuvres exposées appartiennent à l'Association pour le Développement des Échanges France-Chine
        (ci-après dénommée ADEFC) ou à ses ayants droit. Aucune œuvre exposée sera restituée. Si une œuvre exposée est
        par la suite mise aux enchères ou vendue, la répartition du produit net de la vente sera 50 % pour l'ADEFC, 15 %
        pour l’artistes de l’œuvre initiale, 35% pour le participant (hors taxes et commissions).
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        9. Dans le cas de l'une des infractions suivantes au cours de l’événement, l’infracteur assumera, en fonction de
        la situation, des responsabilités civiles telles que l'arrêt de l'infraction, l'élimination de l'impact, la
        présentation d'excuses, l'indemnisation des pertes, etc. Si l'intérêt public est lésé, l’infracteur peut être
        ordonné par le département de l'administration du droit d'auteur à arrêter l'infraction, à confisquer les gains
        illégaux, à confisquer et à détruire les copies contrefaites, et peut être imposé d’une amende. Si les
        circonstances sont suffisamment graves pour constituer un crime, on fera l'objet d'une enquête de responsabilité
        légale conformément à la loi :
      </div>
      <!-- <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        X. In addition to the right of authorship of the exhibition works, exhibitors enjoy other copyright of the
        exhibition works, including but not limited to the reproduction right, distribution right, exhibition right,
        performance right, projection right, information network communication right, production right, adaptation
        right, translation right, compilation right and derivative copyright of the exhibition works, which are owned by
        Association pour le Développement des échanges France-Chinois or the designated party.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        XI. In case of the following infringement acts during joint activities, the infringer shall bear civil
        liabilities according to the circumstances, such as stopping the infringement, eliminating the impact, making an
        apology and compensating for losses; in case of damage to the public interest, the copyright administration
        department may order him to stop the infringement, confiscate the illegal income, confiscate and destroy the
        copies, and impose fine; in case of a crime due to serious circumstances, he shall be investigated for the legal
        responsibility according to law:
      </div> -->
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        a. Exposer ses propres œuvres sans l'autorisation du titulaire du droit d'auteur ;
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        b. Exposer les œuvres d'autrui comme les leurs sans l'autorisation des auteurs ;
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        c. Déformer, falsifier ou plagier les œuvres d'autrui ;
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        d. Autres actes de violation du droit d'auteur et d’autres droits.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        10. En cas de facteurs non humains ou d'autres événements imprévus indépendants de la volonté de l'organisateur
        de l'événement, y compris les tremblements de terre, les incendies, les inondations, les émeutes, les guerres,
        les grèves, les épidémies, les changements dans les lois gouvernementales et autres événements de force majeure,
        l'organisateur se réserve le droit d'annuler, de reporter ou d'adapter en conséquence l’événement et son
        organisation.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        11. Le participant et ses tuteurs ont lu attentivement le contenu de cette déclaration. Une fois qu'ils ont
        participé à tout ou une telle partie de l’événement, il est considéré que le participant et ses tuteurs ont
        totalement compris et accepté tous les éléments, y compris cette déclaration et sont prêts à supporter toutes
        les conséquences juridiques découlant de l'acceptation de cette déclaration.
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        12. L'ADEFC ou ses ayants droit se réserve le droit d'interprétation finale des règles de l’événement.
      </div>
    </div>
  </div>
</template>

<script>
import * as eventHelper from "@/utils/eventHelper";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      icons: this.$root.icons,

      banner: "",
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    this.getBanner();
  },
  methods: {
    ...mapActions({
      positionGetContentAction: "positionGetContent",
    }),
    goArtists() {
      // this.$router.push({
      //   name: "artists",
      // });
      this.$router.push({
        name: "create",
      });
    },
    goMilk() {
      window.open(
        "https://shop92949538.m.youzan.com/v2/showcase/homepage?alias=eC6Gfn4IzM&dc_ps=2841760543432437765.300001"
      );
    },
    getBanner() {
      this.positionGetContentAction({
        position_id: 7,
      }).then((res) => {
        this.banner = res.img_url;
      });
    },
  },
};
</script>

<style scoped>
.intro-content-width {
  width: 800px;
}
.divide {
  background-color: #ffffff;
  height: 1px;
  width: 500px;
  opacity: 0.5;
}
.intro-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  letter-spacing: 0;
  text-align: center;
}
.intro-title-white {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 35px;
}
.intro-title-blue {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #062c3e;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white-24 {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-blue {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #062c3e;
  letter-spacing: 0;
  line-height: 44px;
}
.intro-title-yellow {
  font-family: ZhenyanGB-Regular;
  font-size: 30px;
  color: #f7eb50;
  letter-spacing: 0;
}
.intro-btn {
  cursor: pointer;
  background: #062c3e;
  border-radius: 8px;
  padding: 0 30px;
  height: 65px;
  align-self: flex-start;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 65px;
}

.intro-red {
  color: #f72e02;
}
.intro-blue {
  color: #0088cd;
}
.intro-black {
  color: #000000;
}

.intro-18 {
  font-size: 18px;
}
</style>